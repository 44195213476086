import { HttpClient, HttpClientModule } from "@angular/common/http";
import { isDevMode, NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { MatTableModule } from "@angular/material/table";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IonicModule } from "@ionic/angular";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BaseComponentsModule } from "./base/components/base-components.module";
import { BusinessComponentsModule } from "./business/components/business-components.module";
import { PagesModule } from "./business/pages/pages.module";
import { ApiModule, BASE_PATH } from "./generated/api";

/**
 * Factory for the translation loader.
 * @param http - The client to use for the translation loader.
 * @returns TranslateHttpLoader.
 */
export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

/**
 * The main module of the application.
 */
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        PagesModule,
        BaseComponentsModule,
        FontAwesomeModule,
        MatButtonModule,
        MatCardModule,
        ApiModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000"
        }),
        MatListModule,
        BusinessComponentsModule,
        MatTableModule,
        IonicModule.forRoot({})
    ],
    providers: [
        { provide: BASE_PATH, useValue: environment.backendBaseUrl }
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
