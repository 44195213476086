import { CloneUtil } from "../../base/helper/clone-util";
import { CryptoHelper } from "../../base/helper/crypto-helper";
import { JobTemplate } from "./job-template";
import { MeasuredPoint } from "./measured-point";
import { Photo } from "./photo";
import { Property } from "./property";

/**
 * Job is a DTO to hold all values to describe a job.
 */
export class Job extends JobTemplate {
    public static jobPropertyNameRegistrationNumber: string = "Job.registrationNumber";
    public static jobPropertyClient: string = "Job.client";
    public static jobPropertyName: string = "Job.name";

    public measuredPoints: Array<MeasuredPoint> = [];
    public photos: Array<Photo> = [];
    public jobTemplateId: number|undefined;

    public static from(jobTemplate: JobTemplate): Job {
        const job: Job = {
            ...CloneUtil.clone(jobTemplate),
            id: undefined,
            correlationId: CryptoHelper.getUUID(),
            version: 0,
            jobTemplateId: jobTemplate.id,
            measuredPoints: this.initializeMeasuredPoints(jobTemplate),
            photos: []
        };
        job.properties.push({
            nameKey: "Job.jobTemplate",
            nameEditable: false,
            value: this.getJobTemplateDisplayName(jobTemplate),
            deletable: false,
            deleted: false,
            isNew: false
        });
        job.properties.push({
            nameKey: Job.jobPropertyClient,
            nameEditable: false,
            value: undefined,
            deletable: false,
            deleted: false,
            isNew: false
        });
        job.properties.push({
            nameKey: Job.jobPropertyNameRegistrationNumber,
            nameEditable: false,
            value: undefined,
            deletable: false,
            deleted: false,
            isNew: false
        });

        job.properties = job.properties.filter((p: Property) => {
            const remove: boolean = [JobTemplate.jobTemplatePropertyName].includes(`${p.nameKey}`);
            return !remove;
        });
        return job;
    }

    private static initializeMeasuredPoints(jobTemplate: JobTemplate): Array<MeasuredPoint> {
        const measuredPoints: Array<MeasuredPoint> = [];
        for (const part of jobTemplate.parts) {
            if (part.image) {
                for (const measurementPoint of part.image?.measurementPoints) {
                    measuredPoints.push({
                        measurements: [],
                        partId: part.id!,
                        measurementPointId: measurementPoint.id!
                    });
                }
            }
        }
        return measuredPoints;
    }

    public static getJobDisplayName(job: Job): string|undefined {
        const parts: Array<string> = [];
        parts.push(`${job.id}`);
        if (job.properties) {
            const reg: Property|undefined = Property.findByNameKey(Job.jobPropertyNameRegistrationNumber, job.properties);
            const customer: Property|undefined = Property.findByNameKey(Job.jobPropertyClient, job.properties);

            if (reg?.value) {
                parts.push(reg.value);
            }
            if (customer?.value) {
                parts.push(customer.value);
            }
        }
        return parts.join(" - ");
    }
}

