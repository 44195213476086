import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { ModalController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";

import { AppIcons } from "../../../app-icons";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { BluetoothStatus } from "../../../base/services/ble/bluetooth-status";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { UiHelper } from "../../helpers/ui-helper";
import { BleStatusComponent } from "../ble-status/ble-status.component";

/**
 * Menubar for every page.
 */
@Component({
    selector: "app-menubar",
    templateUrl: "./menubar.component.html",
    styleUrls: ["./menubar.component.scss"]
})
export class MenubarComponent implements OnInit, OnDestroy {
    constructor(
        private readonly bluetoothService: BluetoothService,
        private readonly navigationService: NavigationService,
        platform: Platform,
        private modalController: ModalController
    ) {
        this.bluetoothStatus = this.bluetoothService.allDevicesStatus;
        this.isiOS = platform.is("ios");
    }

    @Input()
    public hideTitleOniOS = false;

    @Input()
    public hideBleStatus = false;

    @Input()
    public header: string = "";

    @Input()
    public headerUntranslated: string = "";

    @Input()
    public showBackButton: boolean = true;

    public bluetoothStatusEnum: typeof BluetoothStatus = BluetoothStatus;
    public isiOS: boolean;
    public bluetoothStatus: BluetoothStatus;
    protected readonly appIcons: typeof AppIcons = AppIcons;
    private connectionStatusSubscription?: Subscription;

    public get deviceConnected(): boolean {
        return this.bluetoothStatus == BluetoothStatus.connected;
    }

    public ngOnInit(): void {
        this.connectionStatusSubscription = this.bluetoothService.allDevicesStatusChanged.subscribe((next: BluetoothStatus) => {
            this.bluetoothStatus = next;
        });
        this.bluetoothStatus = this.bluetoothService.allDevicesStatus;
    }

    public ngOnDestroy(): void {
        if (this.connectionStatusSubscription) {
            this.connectionStatusSubscription.unsubscribe();
        }
    }

    public async openBleStatusPage(): Promise<void> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: BleStatusComponent,
            presentingElement: document.querySelector(".ion-page") as HTMLElement
        });

        await modal.present();
    }

    public statusToIcon(status: BluetoothStatus): IconDefinition {
        return UiHelper.getBluetoothStatusIcon(status);
    }
}
