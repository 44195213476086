import { BackendService } from "../../../../business/services/backend/backend-service";
import { DetailometerIq } from "./detailometer-iq";

/**
 * This class implements creating a connection the device "IQ" via bluetooth low energy and handles reading measurements from it.
 */
export class Iq extends DetailometerIq {
    public static readonly deviceName: string = "IQ";
    public override requiredNamePrefix?: string = "IQ-";

    public override readonly hiddenValues: Array<string> = [
        "Time",
        "Date",
        "Pass/Fail",
        "Calibrated",
        "Serial No.",
        "Certified",
        "Cdiode",
        "Haze C",
        "HAZE C",
        "ASTM D4039"
    ];

    constructor(backendService: BackendService) {
        super(backendService, Iq.deviceName);
    }
}
