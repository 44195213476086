<ion-tabs>
    <ion-tab-bar *ngIf="showNavBar" slot="bottom">
        <ng-container *ngFor="let menuItem of items">
            <ion-tab-button *ngIf="menuItem.visibility != 'sidebar' && menuItem.visibility != 'none'" [tab]="menuItem.path">
                <fa-icon [icon]="menuItem.icon"></fa-icon>
                <span>{{menuItem.nameKey | translate}}</span>
            </ion-tab-button>
        </ng-container>
    </ion-tab-bar>
</ion-tabs>
