import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ModalController, Platform } from "@ionic/angular";

import { BaseComponent } from "../../../base/components/base/base-component";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { SelectJobTemplateComponent } from "../../components/select-job-template/select-job-template.component";
import { Job } from "../../datamodel/job";
import { JobTemplate } from "../../datamodel/job-template";
import { Property } from "../../datamodel/property";
import { JobService } from "../../services/job/job.service";

/**
 * Start page for the Job Mode. Displays a list with previous jobs.
 */
@Component({
    selector: "app-job-list",
    templateUrl: "./job-list.component.html",
    styleUrls: ["./job-list.component.scss"]
})
export class JobListComponent extends BaseComponent {
    constructor(
        private readonly jobService: JobService,
        private readonly dialog: MatDialog,
        private readonly navigationService: NavigationService,
        private readonly modalController: ModalController,
        platform: Platform
    ) {
        super();
        this.isiOS = platform.is("ios");
    }

    protected readonly jobClass: typeof Job = Job;
    protected jobs: Array<Job> = [];
    protected filteredJobs: Array<Job> = [];
    protected searchQuery: string = "";
    protected isiOS: boolean;

    protected componentInit(): void {
        // Do nothing for now
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    protected override componentEnter(): void {
        this.initialize().then();
    }

    private async initialize(): Promise<void> {
        await this.updateJobs();
    }

    private async updateJobs(): Promise<void> {
        this.jobs = await this.jobService.listJobs();
        this.applySearchFilter();
    }

    public emptyQuery(): void {
        this.searchQuery = "";
        this.applySearchFilter();
    }

    public applySearchFilter(): void {
        const query: string = this.searchQuery.trim().toLowerCase();

        if (!query) {
            this.filteredJobs = this.jobs;
            return;
        }

        const filtered: Array<Job> = [];
        const queryAsNumber: number = Number.parseInt(query, 10);

        for (const job of this.jobs) {
            const clientName: string|undefined = Property.findByNameKey(Job.jobPropertyClient, job.properties)?.value?.toLowerCase();
            const registrationNumber: string|undefined = Property.findByNameKey(Job.jobPropertyNameRegistrationNumber, job.properties)?.value?.toLowerCase();
            if ((!Number.isNaN(queryAsNumber) && job.id == queryAsNumber) || clientName?.includes(query) || registrationNumber?.includes(query)) {
                filtered.push(job);
            }
        }

        this.filteredJobs = filtered;
    }

    public navigateTo(path: string): void {
        this.navigationService.navigateForward(path).then();
    }

    public async startNewJob(): Promise<void> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: SelectJobTemplateComponent,
            presentingElement: document.querySelector(".ion-page") as HTMLElement
        });

        await modal.present();

        const { data }: { data?: JobTemplate } = await modal.onWillDismiss<JobTemplate>() as {
            data?: JobTemplate;
        };

        if (data) {
            const jobId: number = await this.jobService.createJob(data);
            await this.navigationService.navigateForward(`jobs/${jobId}`);
        }
    }

    public async navigateToJob(job: Job|JobTemplate): Promise<void> {
        await this.navigationService.navigateForward(`jobs/${job.id}`);
    }

}
