<app-menubar [showBackButton]="false" [hideTitleOniOS]="true" header="QuickMode.title">
    <span class="toolbarSpacer"></span>

    <div class="noMenu">
        <button (click)="downloadCsv()" class="iconButton" color="primary" mat-stroked-button>
            <fa-icon [icon]="appIcons.downloadFile"></fa-icon>
            <span>{{'CsvExport.export' | translate}}</span>
        </button>

        <button (click)="downloadPdf()" class="iconButton" color="primary" mat-stroked-button>
            <fa-icon [icon]="appIcons.downloadFile"></fa-icon>
            <span>{{'PdfExport.export' | translate}}</span>
        </button>

        <button (click)="deleteAllMeasurements()" class="iconButton" color="primary" mat-stroked-button>
            <fa-icon [icon]="appIcons.trash"></fa-icon>
            <span>{{'QuickMode.deleteAllMeasurements' | translate}}</span>
        </button>
    </div>

    <div class="optionsMenu">
        <button [matMenuTriggerFor]="optionsMenu" class="iconButton" color="primary" mat-stroked-button>
            <fa-icon
                [icon]="appIcons.expand"
                class="align-right"
            />
        </button>
        <mat-menu #optionsMenu>
            <div class="menuContent">
                <button (click)="downloadCsv()" class="iconButton menuItem" color="primary" mat-stroked-button>
                    <fa-icon [icon]="appIcons.downloadFile"></fa-icon>
                    <span>{{'CsvExport.export' | translate}}</span>
                </button>

                <button (click)="downloadPdf()" class="iconButton menuItem" color="primary" mat-stroked-button>
                    <fa-icon [icon]="appIcons.downloadFile"></fa-icon>
                    <span>{{'PdfExport.export' | translate}}</span>
                </button>

                <button (click)="deleteAllMeasurements()" class="iconButton menuItem" color="primary" mat-stroked-button>
                    <fa-icon [icon]="appIcons.trash"></fa-icon>
                    <span>{{'QuickMode.deleteAllMeasurements' | translate}}</span>
                </button>
            </div>
        </mat-menu>
    </div>

</app-menubar>

<ion-content [fullscreen]="true" class="content ion-padding">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">{{'QuickMode.title' | translate}}</ion-title>
        </ion-toolbar>
    </ion-header>

    <app-measurement-table #measurementTableComponent
                           (deleteMeasurementEvent)="bluetoothService.deleteMeasurement($event)"
                           [highlightLastMeasurement]="true"
                           [measurements]="measurements"
                           [showStatistics]="true">
    </app-measurement-table>
</ion-content>
