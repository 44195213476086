<ion-header [translucent]="true">
    <ion-toolbar>
        <ion-title *ngIf="!(hideTitleOniOS && isiOS)">{{header | translate}}{{headerUntranslated}}</ion-title>

        <ion-buttons *ngIf="showBackButton" slot="start">
            <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>

        <ion-buttons slot="end">
            <ng-content></ng-content>

            <ng-container *ngIf="!hideBleStatus" [ngSwitch]="bluetoothStatus">
                <button *ngSwitchCase="bluetoothStatusEnum.unknown" color="primary" mat-stroked-button>
                    <fa-icon [icon]="appIcons.deviceStatusCheck" class="align-right" />
                </button>
                <button (click)="openBleStatusPage()" *ngSwitchCase="bluetoothStatusEnum.unavailable" color="warn" mat-stroked-button>
                    <fa-icon [icon]="appIcons.deviceUnavailable" class="align-right" />
                </button>
                <button *ngSwitchDefault [class.connected]="bluetoothStatus == bluetoothStatusEnum.connected" [matMenuTriggerFor]="bleMenu" color="primary" mat-stroked-button>
                    <fa-icon [icon]="statusToIcon(bluetoothStatus)" class="align-right" />
                </button>
            </ng-container>
        </ion-buttons>
    </ion-toolbar>

    <mat-menu #bleMenu>
        <div class="menuContent">
            <app-device-connector></app-device-connector>
        </div>
    </mat-menu>
</ion-header>
