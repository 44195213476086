import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { BaseComponent } from "../../../base/components/base/base-component";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { ErrorDialogComponent } from "../../components/error-dialog/error-dialog.component";
import { ErrorMessage } from "../../components/error-dialog/error-message";
import { JobTemplate } from "../../datamodel/job-template";
import { Property } from "../../datamodel/property";
import { JobTemplateService } from "../../services/job-template/job-template.service";
import { JobTemplateImporter } from "../job-designer/sharing/job-template-importer";

/**
 * JobListComponent displays all available job templates.
 */
@Component({
    selector: "app-job-template-list",
    templateUrl: "./job-template-list.component.html",
    styleUrls: ["./job-template-list.component.scss"]
})
export class JobTemplateListComponent extends BaseComponent {
    constructor(
        private jobTemplateService: JobTemplateService,
        private navigationService: NavigationService,
        private jobTemplateImporter: JobTemplateImporter,
        private translateService: TranslateService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog) {
        super();

        this.refresh().then();
    }

    @ViewChild("fileSelector")
    private fileSelectorRef?: ElementRef<HTMLInputElement>;

    public displayedColumns: Array<string> = ["id", "name"];
    public jobTemplates: Array<JobTemplate> = [];
    protected readonly jobClass = JobTemplate;
    private demoTemplateInstallerDialogCloseSubscription?: Subscription;
    public filteredJobTemplates: Array<JobTemplate> = [];
    protected searchQuery: string = "";

    protected componentInit(): void {
        // Do nothing
    }

    protected componentDestroy(): void {
        this.demoTemplateInstallerDialogCloseSubscription?.unsubscribe();
    }

    public override componentEnter(): void {
        this.initialize().then();
    }

    private async refresh(): Promise<void> {
        this.jobTemplates = await this.jobTemplateService.listJobTemplates();
    }

    private async initialize(): Promise<void> {
        await this.updateJobTemplates();
    }

    private async updateJobTemplates(): Promise<void> {
        this.jobTemplates = await this.jobTemplateService.listJobTemplates();
        this.applySearchFilter();
    }

    public emptyQuery(): void {
        this.searchQuery = "";
        this.applySearchFilter();
    }

    public applySearchFilter(): void {
        const query: string = this.searchQuery.trim().toLowerCase();

        if (!query) {
            this.filteredJobTemplates = this.jobTemplates;
            return;
        }
        const filtered: Array<JobTemplate> = [];
        const queryAsNumber: number = Number.parseInt(query, 10);

        for (const jobTemplate of this.jobTemplates) {
            const jobTemplateName: string|undefined = Property.findByNameKey(JobTemplate.jobTemplatePropertyName, jobTemplate.properties)?.value?.toLowerCase();
            if ((!Number.isNaN(queryAsNumber) && jobTemplate.id == queryAsNumber) || jobTemplateName?.includes(query)) {
                filtered.push(jobTemplate);
            }
        }

        this.filteredJobTemplates = filtered;
    }

    public navigateTo(path: string): void {
        this.navigationService.navigateForward(path).then();
    }

    public loadJob(job: JobTemplate): void {
        this.navigationService.navigateForward(`job-templates/${job.id}`).then();
    }

    public async importTemplate(): Promise<void> {
        if (this.fileSelectorRef) {
            this.fileSelectorRef.nativeElement.onchange = async (event: Event): Promise<void> => {
                const files: FileList|null = (event.target as HTMLInputElement)?.files;
                if (files && files.length > 0) {
                    try {
                        this.snackbar.open(this.translateService.instant("JobTemplateList.importing"), undefined, {
                            duration: this.snackbarDuration,
                            verticalPosition: this.snackbarVerticalPosition
                        });
                        await this.jobTemplateImporter.import(files[0]);
                        await this.updateJobTemplates();
                        this.snackbar.open(this.translateService.instant("JobTemplateList.jobTemplateImported"), undefined, {
                            duration: this.snackbarDuration,
                            verticalPosition: this.snackbarVerticalPosition
                        });
                    } catch (error: Error|any) {
                        this.dialog.open(ErrorDialogComponent, {
                            data: {
                                title: "ErrorDialog.importFailed",
                                advice: [
                                    "ErrorDialog.adviceContactSupport"
                                ],
                                details: [`${error.message}` ?? ""],
                                severity: "error",
                                buttons: []
                            } as ErrorMessage
                        });
                    } finally {
                        if (this.fileSelectorRef) {
                            this.fileSelectorRef.nativeElement.value = "";
                        }
                    }
                }
            };
            this.fileSelectorRef.nativeElement.click();

        }
    }
}
