import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { register } from "swiper/element/bundle";

import { environment } from "../environments/environment";
import { EventHelper } from "./base/helper/event-helper";
import { AppUpdateService } from "./base/services/app-update/app-update.service";
import { BluetoothService } from "./base/services/ble/bluetooth.service";
import { NavigationService } from "./base/services/navigation/navigation.service";
import { TimeService } from "./base/services/time/time.service";
import { Timer } from "./base/services/time/timer";
import { UiHelper } from "./business/helpers/ui-helper";
import { AppService } from "./business/services/app/app.service";
import { LoggingService } from "./business/services/logging/logging.service";

register();

/**
 * AppComponent.
 */
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    constructor(
        private readonly appService: AppService,
        private readonly navigationService: NavigationService,
        private readonly appUpdateService: AppUpdateService,
        private readonly bluetoothService: BluetoothService,
        private readonly timeService: TimeService,
        loggingService: LoggingService,
        translateService: TranslateService
    ) {
        loggingService.intercept();
        console.info("Initializing app...");

        UiHelper.translateService = translateService;

        translateService.setDefaultLang("en");
        translateService.use("en");
    }

    private postInitTimer?: Timer;

    protected get showMenu(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return screen.width > 810;
    };

    public async ngOnInit(): Promise<void> {
        await this.initialize();
    }

    private async initialize(): Promise<void> {
        await this.appUpdateService.initialize();

        await this.appService.initialize();

        await this.bluetoothService.initialize();

        this.appService.notifyAppCompletelyInitialized();

        this.postInitTimer = this.timeService.spawnTimer(environment.postAppInitDelay);
        EventHelper.subscribe(this.postInitTimer.elapsed, this.postInitApp, this);
        this.postInitTimer.start();
    }

    private postInitApp(): void {
        this.postInitTimer!.stop();

        this.appUpdateService.startUpdateCheck(environment.appUpdateCheckInterval, true);
        console.info("Post init done.");
    }
}
